<template>
  <div class="mePage">

    <div class="logo">
      <img class="logoImg" src="../../src/static/logo.png" alt="" srcset="" />
      <div class="lianjie">
        <span v-if="!userAddress">连接</span>
      		<span v-if="userAddress">{{userAddress.substring(0,10)+'...'+userAddress.substring(36)}}</span>
        <div class="quan" v-if="!userAddress">
          <img src="../../src/static/youshang.png" alt="" srcset="">
        </div>
      </div>
    </div>
    <div class="meMain">
      <div class="shengfen">
        <div class="topBox">
          <div class="tabs">
            <!-- <span @click="actIndex = 0" :class="actIndex == 0?'active':''">身份：</span>
            <span @click="actIndex = 1" :class="actIndex == 1?'active':''">节点用戶</span> -->
			<span  :class="''"></span>
			<span  :class="'active'">{{getrefMap[3]?'身份：Noder':'身份：普通用户'}}</span>
          </div>
          <div class="shouyi">
            <div class="list">
              <div class="dessc">累计燃烧收益</div>
              <div class="val">{{getrefMap2[6].toFixed(4)}}BNB</div>
            </div>
            <div class="list" style=" text-align: right;">
              <div class="dessc">待分红</div>
              <div class="val">{{getrefMap[5].toFixed(4)}}BNB</div>
            </div>
            <div class="list">
              <div class="dessc">可领取分红</div>
              <div class="val">{{viewDividAmount.toFixed(2)}}{{tokenName}}</div>
            </div>
            <div class="list" style="display: flex;justify-content: flex-end;">
              <span class="get" @click="dividMe" v-if="viewDividAmount>0">领取</span>
			  <span class="get" @click="viewDividAmount2" v-if="viewDividAmount==0">查看</span> 
			  <!-- <span class="get" @click="dividMe">领取</span> -->
            </div>
          </div>


          <div class="shouyi">
            <div class="list">
              <div class="dessc">累计已获得推广奖励</div>
              <div class="val">{{(getrefMap3[4]+getrefMap2[0]).toFixed(2)}}{{tokenName}}</div>
            </div>
            <div class="list" style=" text-align: right;">
              
            </div>
            <div class="list">
              <div class="dessc">直推奖励</div>
              <div class="val">{{getrefMap3[4].toFixed(2)}}{{tokenName}}</div>
            </div>
            <div class="list" style=" text-align: right;">
              <div class="dessc">Noder奖励</div>
              <div class="val">{{getrefMap2[0].toFixed(2)}}{{tokenName}}</div>
            </div>
          </div>
        </div>
      </div>


      <div class="zhiboliu" style="height: 100%;">
        <div class="libne"></div>
        <div class="list">
          <div>直推燃烧地址数</div>
          <div style="font-size: 26px;font-weight: 600;" >
		  {{getrefMap[1]}} 
		  <span v-if="showChildFlag" @click="showChild(false)" style="color: #F5B22F;">隐藏</span>
		  <span v-if="!showChildFlag" @click="showChild(true)" style="color: #F5B22F;">显示</span>
		  </div>
		  
		  <div v-if="showChildFlag" v-for="(item,index) in myChildAddress">{{item.substring(0,10)+'...'+item.substring(36)}}</div>
        </div>
        <div class="list" style="text-align: right;">
          <div>团队燃烧金额</div>
          <div style="font-size: 26px;font-weight: 600;">{{getrefMap3[6].toFixed(4)}}BNB</div>
        </div>
      </div>


      <!-- <div class="submit" @click="loadData()">更新我的推广数据</div> -->


      <div class="descText">
        <div>
          PMM（Perpetual Motion Machine )由Sandbox‌早期社区发起，致力于打造一个BSC生态的Web3.0应用应用聚合分发平台（即Web3.0应用盒子)。PMM基于314协议框架的底层技术开发，为BSC生态内区块链FEFI创业者，提供一个集项目发布、项目众筹、代币空投、项目发布与一体的去中心化的创业平台。   
        </div>
        <div>
          PMM依托314协议的DeFi环境优势，为BSC生态内的应用创建者提供了天然的流通条件。结合独特的自有价值生长体系，让PMM成为区块链玩家首选的应用分发渠道。     
        </div>
        <div>
          PMM——为聚集流量而生，自带价值生长系统！        
        </div>
        <div>
          PMM发行同名代币——PMM代币，以流通量为激励基础，结合有序的永动通缩机制，凝聚用户的集体共识，让PMM的TVL稳步上升。在用户一次次的交互过程中，实现真正的用户价值自治。  
        </div>
        <div>
          PMM——为社群领袖量身打造，自带生态生长系统。PMM首创Create-Mint模式，汇聚超500社群领袖，共建生态发展。
        </div>
      </div>

      <div class="shareBox">
          <div class="name">Contact us！</div>
          <div class="shareIcon">
            <span class="bntn">
              Get started
            </span>
            <span class="icon">
              <img src="../static/share1.png" alt="" srcset="">
            </span>
            <span class="icon">
              <img src="../static/share2.png" alt="" srcset="">
            </span>
            <span class="icon">
              <img src="../static/share3.png" alt="" srcset="">
            </span>
          </div>
      </div>
      <div style="height:170px"></div>
    </div>

    <tab :pageActive="1"></tab>
    
  </div>
</template>

<!-- lang="ts" -->
<script>
import Web3 from "web3";
import MyContractABI from "../abi/main.json"; // 导入合约 ABI
import { message } from "ant-design-vue";
import Language from "../lan/lan.json"; //
import tab from "../../src/components/tab.vue";
import Spin from '@/utils/spinLoading.js'
export default {
  // computed: {
  //   author () {
  //     return process.env.VUE_APP_AUTHOR;
  //   },
  //   apiBaseUrl () {
  //     return process.env.VUE_APP_API_BASE_URL;
  //   }
  // }
  components: {
    tab
  },
  data() {
    return {
      actIndex:0,
      defaultProps: {
        children: "children",
        label: "label",
      },

      //要修改的参数
      contractAddress: "0xe754bf71cfef48d116909f00a78a63d4416104ab",
      //networkId:97,
      networkId: 56,
      //end
      userAddress: "",
      dataStat: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      tokenName: "PMM",
      mintAmount: 0,
	  showChildFlag:false,
      price: 0,
      nodeCount: 0,
      refBurnAmount: 0,
	  loaded:false,
      refBurnAmountS: 0,
      totalWhiteDividAmount: 0,
      totalWhiteDividAmountS: 0,
	  viewDividAmount:0,
	  address0:"0x0000000000000000000000000000000000000000",
      dataStat2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataStat3: [this.address0,this.address0, this.address0, 0,0,0, 0, 0, 0],
      getrefMap: [this.address0,0, 0,0,0,0,0, 0, 0, 0],
      getrefMap2: [0, 0, 0, 0,0,0,0, 0, 0],
	  getrefMap3: [0, 0, 0, 0,0,0,0, 0, 0],
      param: [0,0,0,this.address0,this.address0,0,0,0,0,0,],
      exchangeAmount: 0,
      data2: {},
      bnbBalance: 0,
      topAmount1: 0,
      topAmount2: 0,
      topAmount3: 0,
      web3: {},
      myChildAddress: [],
      burnAmountR: 0,
      Language: Language,
      selectLan: "en",
    };
  },
  created() {
    if (localStorage.getItem("language")) {
      this.selectLan = localStorage.getItem("language");
    }
    this.connectBSC(true);
    let that = this;
    this.interval = setInterval(function () {
      that.loadData();
    }, 1000 * 20);
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
	  async showChild(flag) {
		this.showChildFlag = flag;
	    this.queryMyChild();
	  },
    async queryMyChild() {
      this.myChildAddress = [];
      for (let i = 0; i < this.getrefMap[1]; i++) {
        console.log("1111111111111111");
        let childAddresses = await this.contract.methods
          .childAddresses(this.userAddress, i)
          .call({});
        console.log(childAddresses, "childAddresses");
        this.myChildAddress.push(childAddresses);
      }
      message.success("query success!");
    },
	async viewDividAmount2() {
	  /* const receipt = await this.contract.methods.viewDividAmount().send({ from: this.userAddress });
	  console.log(receipt,'receipt');
	  const events = receipt.events;
	  console.log(events,'events');
	  
	  this.contract.methods.viewDividAmount().call({}, (error, result) => {
	      if (!error) {
	          // 获取返回值
			  console.log(result,'result222');
	          const amount = result.returnValues.amount;
	          // 处理返回值
	      }
	  }); */
	  let currentTime = await this.getCurrentBlockTimestamp();
	  currentTime = parseFloat(currentTime+'');
	  for (let i = 0; i < 999; i++) {
	    
	    let burnBnbValue = await this.contract.methods.burnBnbList(this.userAddress, i).call({});
		burnBnbValue = parseFloat(this.web3.utils.fromWei(burnBnbValue, "ether"));
		console.log(burnBnbValue,"burnBnbValue");
		if(burnBnbValue>0){
			// this.viewDividAmount = burnBnbValue*this.price;
			let burnTime = await this.contract.methods.burnTimeList(this.userAddress, i).call({});
			burnTime = parseFloat(burnTime);
			if((currentTime-burnTime)>this.param[9]){
				this.viewDividAmount = burnBnbValue*this.price;
			}
		}
	  }
	},
	async getCurrentBlockTimestamp() {
	  try {
	    const blockNumber = await this.web3.eth.getBlockNumber();
	    const block = await this.web3.eth.getBlock(blockNumber);
	    const timestamp = block.timestamp;
		return timestamp;
	    //console.log(timestamp); // 输出当前区块的时间戳
	  } catch (error) {
	    console.error(error);
	  }
	},
    async queryNodeCount() {
      for (let i = 0; i < 10000; i++) {
        await this.contract.methods.nodeAddresses(i).call({});
        this.nodeCount++;
      }
    },

    back() {
      this.$router.replace("/?notTo=1");
    },
    mint() {
      if (!this.mintAmount) {
        message.error("must amount!");
        return;
      }
      if (this.mintAmount > this.param[2] || this.mintAmount < this.param[1]) {
        message.error("amount need " + this.param[1] + "-" + this.param[2]);
        return;
      }

      if (
        parseFloat(this.getrefMap[4]) + parseFloat(this.mintAmount) >
        this.param[2]
      ) {
        message.error("mint max " + this.param[2]);
        return;
      }

      const value = this.web3.utils.toWei(this.mintAmount, "ether"); // 以太转账金额，这里以1以太为例

      this.web3.eth.sendTransaction(
        {
          from: this.userAddress,
          to: this.contractAddress,
          value: value,
        },
        (error, transactionHash) => {
          if (!error) {
            message.error("error:" + transactionHash);
            console.log(transactionHash); // 交易哈希
          } else {
            //message.error(error);
            console.error(error);
          }
        }
      );
    },
    max() {
      this.mintAmount = this.param[2] - this.getrefMap[4];
    },
    async connectBSC(queryChildFlag) {
      // if (window.ethereum) {
      //     await window.ethereum.request({method: 'eth_requestAccounts'});
      //     window.web3 = new Web3(window.ethereum);
      //     return true;
      //   }
      let that = this;
      if (window.ethereum) {
        try {
          window.ethereum.on("accountsChanged", function (accounts) {
            // accounts 是一个数组，包含当前连接的所有账户
            console.log("change address:", accounts[0]);
            that.connectBSC();
            // 在这里处理账户变更后的逻辑
            // 例如更新界面上显示的账户信息等
          });
          console.log(window.ethereum, "window.ethereum");
          // 请求用户授权连接到BSC
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          console.log(accounts, "accounts");

          // 创建web3实例
          this.web3 = new Web3(window.ethereum);

          // 获取用户地址
          this.userAddress = accounts[0].toLowerCase(); // 更新用户地址
          //this.userAddress = "";

          let bnbBalance = await this.web3.eth.getBalance(this.userAddress);
          this.bnbBalance = this.web3.utils.fromWei(bnbBalance, "ether");

          // 加载合约
          console.log(MyContractABI, "MyContractABI");
          const networkId = await this.web3.eth.net.getId();
          if (networkId != this.networkId) {
            message.error("err network，please select BNB chain");
          }
          //const deployedNetwork = MyContractABI.networks[networkId];
          this.contract = new this.web3.eth.Contract(
            MyContractABI,
            // deployedNetwork && deployedNetwork.address
            this.contractAddress
          );

          this.connected = true;

          this.loadData(queryChildFlag);
        } catch (error) {
          console.error(error);
        }
      } else {
        // alert("Please install MetaMask or a BSC-compatible wallet to use this DApp.");
      }
    },
	async dividMe() {
	  this.contract.methods
	    .dividMe()
	    .send({ from: this.userAddress })
	    .on("transactionHash", (hash) => {
	      // 在交易被发送到以太坊网络时触发的事件
	      message.success("submit success");
	      console.log("交易哈希:", hash);
	    })
	    .on("receipt", (receipt) => {
	      // 在交易被打包后确认时触发的事件
	      console.log("交易收据:", receipt);
	      //message.success(receipt.transactionHash);
	      message.success("transaction success");
	      this.loadData();
	    })
	    .on("error", (error) => {
	      // 在交易失败时触发的事件
	      //message.error(error);
	      console.error("交易失败:", error);
	    })
	},
    async loadData(queryChildFlag) {
      if (this.contract) {
        try {
          // 调用合约方法
		  if(!this.loaded){
		  	Spin.show('Loading');
		  }
          let dataStat = await this.contract.methods.getStatData().call({});
          let dataStat2 = await this.contract.methods.getStatData2().call({});
          let param = await this.contract.methods.getParam().call({});
		  
		  //const receipt = await this.contract.methods.viewDividAmount().send({ from: this.userAddress });
		  //const events = receipt.events;
		  //console.log(events,'events');
		  
		  let getrefMap = await this.contract.methods.getrefMap(this.userAddress).call({});
          let burnAmountR = await this.contract.methods.burnAmountR(this.userAddress).call({});
          this.burnAmountR = parseFloat(this.web3.utils.fromWei(burnAmountR, "ether"));
		  
		  if(!this.loaded){
		      this.loaded = true;
		  	  Spin.hide();
		  }

          console.log(this.getrefMap, "this.getrefMap");

          param[1] = this.web3.utils.fromWei(param[1], "ether");
          param[2] = this.web3.utils.fromWei(param[2], "ether");
          param[3] = param[3].toLowerCase();
          param[4] = param[4].toLowerCase();
          param[5] = parseFloat(this.web3.utils.fromWei(param[5], "ether"));
          param[6] = this.web3.utils.fromWei(param[6], "ether");
		  param[9] = parseInt(param[9]);
          console.log(getrefMap[6], "getrefMap[6]");
          getrefMap[6] = parseFloat(this.web3.utils.fromWei(getrefMap[6], "ether"));
          getrefMap[5] = parseFloat( this.web3.utils.fromWei(getrefMap[5], "ether"));
          getrefMap[4] = parseFloat(this.web3.utils.fromWei(getrefMap[4], "ether"));
          getrefMap[1] = parseInt(getrefMap[1]);
          getrefMap[2] = parseInt(getrefMap[2]);
		  getrefMap[3] = parseInt(getrefMap[3]);
          console.log(getrefMap[6], "getrefMap[6]");

          dataStat[1] = parseFloat(this.web3.utils.fromWei(dataStat[1], "ether"));
          dataStat[6] = parseFloat(this.web3.utils.fromWei(dataStat[6], "ether"));
          dataStat[7] = parseFloat(this.web3.utils.fromWei(dataStat[7], "ether"));
          dataStat[2] = parseFloat(this.web3.utils.fromWei(dataStat[2], "ether"));

          dataStat2[0] = parseFloat(this.web3.utils.fromWei(dataStat2[0], "ether"));
          dataStat2[1] = parseFloat(this.web3.utils.fromWei(dataStat2[1], "ether"));
          if (dataStat2[0] && dataStat2[1]) {
            this.price = parseFloat(dataStat2[0]) / parseFloat(dataStat2[1]);
          }
          dataStat2[3] = parseFloat(this.web3.utils.fromWei(dataStat2[3], "ether") );
          dataStat2[4] = parseFloat(this.web3.utils.fromWei(dataStat2[4], "ether"));
          dataStat2[5] = parseFloat(this.web3.utils.fromWei(dataStat2[5], "ether"));
          dataStat2[6] = parseFloat(this.web3.utils.fromWei(dataStat2[6], "ether"));
          dataStat2[7] = parseFloat(this.web3.utils.fromWei(dataStat2[7], "ether"));
		  

          this.dataStat = dataStat;
          this.dataStat2 = dataStat2;
          this.param = param;
          this.getrefMap = getrefMap;
          console.log(this.dataStat, "dataStat:");
          console.log(this.dataStat2, "dataStat2:");
          console.log(this.param, "param:");

          let getrefMap2 = await this.contract.methods.getrefMap2(this.userAddress).call({});
          getrefMap2[0] = parseFloat(this.web3.utils.fromWei(getrefMap2[0], "ether"));
          getrefMap2[1] = parseFloat(this.web3.utils.fromWei(getrefMap2[1], "ether"));
          getrefMap2[3] = parseFloat(this.web3.utils.fromWei(getrefMap2[3], "ether"));
		  getrefMap2[6] = parseFloat(this.web3.utils.fromWei(getrefMap2[6], "ether"));
		  
		  let getrefMap3 = await this.contract.methods.getrefMap3(this.userAddress).call({});
		  getrefMap3[3] = parseFloat(this.web3.utils.fromWei(getrefMap3[3], "ether"));
		  getrefMap3[4] = parseFloat(this.web3.utils.fromWei(getrefMap3[4], "ether"));
		  getrefMap3[6] = parseFloat(this.web3.utils.fromWei(getrefMap3[6], "ether"));

          let dataStat3 = await this.contract.methods.getStatData3().call({});
          console.log(this.dataStat3, "dataStat3:");
          dataStat3[3] = parseFloat(this.web3.utils.fromWei(dataStat3[3], "ether"));
          dataStat3[4] = parseFloat(this.web3.utils.fromWei(dataStat3[4], "ether"));

          let dividAmountMap = await this.contract.methods.dividAmountMap(this.userAddress).call({});
          console.log(dividAmountMap, "dividAmountMap");

          this.getrefMap2 = getrefMap2;
		  this.getrefMap3 = getrefMap3;
          this.dataStat3 = dataStat3;

          if (queryChildFlag) {
            this.queryMyChild();
            this.queryNodeCount();
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.error("Contract not initialized.");
      }
    },
  },
};
</script>


<style>
body {
  background-image: url("../static/bg2.jpg");
  background-size: 100% 100%;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.second {
  width: 90%;
  margin: 10px auto;
  display: block;
  border-radius: 20px;
  border: 3px solid #4f82b4;
  background: rgba(14, 12, 32, 0.5);
}
.second01 {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.second02 {
  font-size: 16px;
}
.second03 {
  width: 40px;
  height: 40px;
}
.second04 {
  width: 90%;
  margin: 18px auto;
  border: 1px solid #d9d9d9;
  height: 180px;
  border-radius: 20px;
  display: block;
}
.second05 {
  width: 90%;
  margin: 25px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.second09 {
  display: inline-block;
  width: 62px;
  height: 40px;
  border-radius: 12px;
  text-align: center;
  line-height: 40px;
  background-color: #7c3bed33;
  color: #7c3bed;
  font-size: 24px;
  margin-left: 20px;
}

.mePage {
  box-sizing: border-box;
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow-y: scroll;
}

.mePage .logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.logoImg {
  width: 112px;
  height: auto;
}

.meMain {
  width: 90%;
  margin: 0 auto;
}

.shengfen {
  width: 100%;
  height: 375px;
  background: #cccccc61;
  border-radius: 10px;
}

.topBox{
  height: 212px;
border-radius: 10px;
background: #222429;
}

.tabs{
  display: flex;
  align-items: center;
  color: #fff;
  height: 50px;
  border-bottom: 1px solid #515151;
}

.tabs span{
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 90px;
  justify-content: center;
  margin-right: 40px;
}

.tabs .active{
  border-bottom: 1px solid #F5B22F;
  color: #F5B22F;
}
.shouyi{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px;
}

.shouyi .list{
  width: 50%;
  color: #fff;
  margin-bottom: 10px;
}

.dessc{
  color: #FFFFFF60;
}


.shouyi .list .val{
  font-size: 24px;
  font-weight: 600;
  color: #FFFFFF;
 
}

.shouyi .list .get{
    height: 30px;
    border-radius: 17px;
    width: 80px;
    background: #ffffff33;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #F5B22F;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .zhiboliu{
    width: 100%;
height: 84px;
border-radius: 10px;

background: linear-gradient(90deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 99%);

box-sizing: border-box;
border: 0.5px solid #FFFFFF;

backdrop-filter: blur(5px);
margin-top: 20px;
display: flex;
align-items: center;
padding: 0 20px;
position: relative;
  }

  .zhiboliu .list{
    width: 50%;
    color: #fff;
    
  }

  .libne{
    width: 1px;
    height: 65%;
    background: #FFFFFF60;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }


  .submit{
    height: 50px;
    border-radius: 150px;
    background: #F5B22F;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-top: 20px;
  }

  .descText{
    color: #fff;
    background: linear-gradient(180deg, rgba(216, 216, 216, 0.7) 0%, rgba(216, 216, 216, 0) 100%);
    border-radius: 20px;
    padding: 20px;
    margin-top: 30px;
  }
  .descText div{
    font-size: 13px;
    margin-bottom: 15px;
    text-indent: 20px;
  }

  .shareBox{
    margin-top: 20px;
    padding: 0 5px;
  }

  .shareBox .name{
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
  }
  .shareIcon{
    display: flex;
    align-items: center;
  }

  .shareIcon .bntn{
    width: 120px;
height: 43px;
border-radius: 12px;
background: radial-gradient(88% 123% at 88% 24%, #FC7373 4%, #FC8B45 51%, #FFCF70 100%);

box-sizing: border-box;
border: 1px solid rgba(255, 255, 255, 0.17);
font-size: 16px;
font-weight: 500;
color: #fff;
display: flex;
align-items: center;
justify-content: center;
margin-right: 30px;

  }

    .shareIcon .icon{
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ffffff60;
      border-radius: 10px;
      margin-right: 10px;
    }

    
.lianjie{
  width: 152px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  padding: 0 5px 0 15px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 12px;
}

.lianjie .quan{
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lianjie .quan img{
  width: 17px;
  height: auto;
}
</style>